import algoliasearch from "algoliasearch/lite"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import qs from "qs"
import React, { FunctionComponent, useRef, useState } from "react"
import {
  connectHighlight,
  connectHits,
  connectPoweredBy,
  connectSearchBox,
  InstantSearch,
} from "react-instantsearch-dom"
import ClearIcon from "../assets/images/clear.svg"
import SearchIcon from "../assets/images/search.svg"
import Layout from "../components/Layout"
import * as searchboxStyles from "../components/SearchForm/searchform.module.scss"
import Seo from "../components/Seo"
import createImage from "../hooks/createImage"
import useSearchConfig from "../hooks/useSearch"
import * as styles from "./zoeken.module.scss"

const Highlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  })

  return (
    <span>
      {parsedHit.map((part) =>
        part.isHighlighted ? (
          <mark key={part.value}>{part.value}</mark>
        ) : (
          part.value
        )
      )}
    </span>
  )
})

const Snippet = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_snippetResult",
    attribute,
    hit,
  })

  return (
    <p>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark key={index}>{part.value}</mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </p>
  )
})

const Hits = connectHits(({ hits }) => (
  <ul className={styles.grid}>
    {hits.map((hit, index) => (
      <li className={styles.gridItem} key={index}>
        <Hit hit={hit} />
      </li>
    ))}
  </ul>
))

const Hit = ({ hit }) => {
  createImage(hit.thumbnail)
  return (
    <Link to={`/${hit.slug}`} className={styles.link}>
      <GatsbyImage
        image={hit.thumbnail.gatsbyImageData}
        alt={hit.title}
        className={hit.slug}
      />
      <div className={styles.cardtext}>
        <h4>
          <Highlight attribute="title" hit={hit} />
        </h4>
        <div>
          <Snippet attribute="contentHtml" hit={hit} />
        </div>
      </div>
    </Link>
  )
}

const PoweredBy = connectPoweredBy(({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    Powered by Algolia
  </a>
))

interface State {
  query: string
}

const MySearchBox = connectSearchBox(({ refine }) => {
  const input = useRef<HTMLInputElement>(null)
  const [state, setState] = React.useState<State>({ query: "" })

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    const q = state.query
    refine(q)
  }

  const handleReset = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    input.current?.focus()
    refine("")
    setState({ query: "" })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    setState({ query: e.target.value })
  }

  const searchboxClasses = [searchboxStyles.sbxMedium, styles.searchbox].join(
    " "
  )

  return (
    <form
      action="/zoeken"
      method="GET"
      autoComplete="on"
      role="search"
      onSubmit={handleSubmit}
      noValidate={true}
      className={searchboxClasses}
      onReset={handleReset}>
      <div role="search" className={searchboxStyles.sbxMedium__wrapper}>
        <button
          type="submit"
          title="Voer uw zoekterm in"
          id="searchSubmit"
          className={searchboxStyles.sbxMedium__submit}>
          <SearchIcon aria-hidden={true} role="img" />
        </button>
        <input
          id="search"
          type="search"
          name="search"
          placeholder="Zoek een recept"
          autoComplete="off"
          required={true}
          value={state.query}
          className={searchboxStyles.sbxMedium__input}
          onChange={handleChange}
          ref={input}
          aria-labelledby="searchSubmit"
        />
        <button
          type="reset"
          title="Clear the search query."
          className={searchboxStyles.sbxMedium__reset}>
          <ClearIcon aria-hidden={true} role="img" />
        </button>
      </div>
    </form>
  )
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchPageProps {
  location: Location
}

const SearchPage: FunctionComponent<SearchPageProps> = ({ location }) => {
  const searchConfig = useSearchConfig()

  const algoliaClient = algoliasearch(
    searchConfig.algoliaAppId,
    searchConfig.searchKey
  )

  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        })
      }

      return algoliaClient.search(requests)
    },
  }

  const DEBOUNCE_TIME = 400

  interface QueryState {
    query?: string
    page?: number
  }

  const createURL = (state: QueryState) => {
    const isDefaultRoute = !state.query && state.page === 1

    if (isDefaultRoute) {
      return ""
    }

    const queryParameters: QueryState = {}

    if (state.query) {
      queryParameters.query = encodeURIComponent(state.query)
    }
    if (state.page !== 1) {
      queryParameters.page = state.page
    }

    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    })

    return `/zoeken/${queryString}`
  }

  const searchStateToUrl = (searchState: QueryState) =>
    searchState ? createURL(searchState) : ""

  const urlToSearchState = (location: Location) => {
    const { query = "", page = 1 } = qs.parse(location.search.slice(1))

    return {
      query: decodeURIComponent(query as string),
      page,
    }
  }

  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const [debouncedSetState, setDebouncedSetState] = useState({})

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetState)

    setDebouncedSetState(
      setTimeout(() => {
        history.pushState(
          {},
          "updatedSearchState",
          searchStateToUrl(updatedSearchState)
        )
      }, DEBOUNCE_TIME)
    )

    setSearchState(updatedSearchState)
  }

  return (
    <Layout showSearchbox={false} useH1Header={true}>
      <Seo />
      <InstantSearch
        indexName={searchConfig.searchIndex}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}>
        <MySearchBox searchState={searchState} />
        <Hits hitComponent={Hit} />
        <PoweredBy
          translations={{
            searchBy: "Search by",
          }}
        />
      </InstantSearch>
    </Layout>
  )
}

export default SearchPage
