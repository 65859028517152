import { Image } from "../models"

const createImage = (image: Image): void => {
  image.fluid = {
    src: image.file.url + "?w=250&h=200&q=75&fit=fill&f=center",
    srcWebp: image.file.url + "?w=250&h=200&q=75&fm=webp&fit=fill&f=center",
    srcSetWebp:
      image.file.url +
      "?w=125&h=100&q=75&fm=webp&fit=fill&f=center 125w, " +
      image.file.url +
      "?w=250&h=200&q=75&fm=webp&fit=fill&f=center 250w, " +
      image.file.url +
      "?w=375&h=300&q=75&fm=webp&fit=fill&f=center 375w, " +
      image.file.url +
      "?w=500&h=400&q=75&fm=webp&fit=fill&f=center 500w, " +
      image.file.url +
      "?w=750&h=600&q=75&fm=webp&fit=fill&f=center 750w, " +
      image.file.url +
      "?w=1600&h=1280&q=75&fm=webp&fit=fill&f=center 1600w",
    srcSet:
      image.file.url +
      "?w=125&h=100&q=75&fit=fill&f=center 125w, " +
      image.file.url +
      "?w=250&h=200&q=75&fit=fill&f=center 250w, " +
      image.file.url +
      "?w=375&h=300&q=75&fit=fill&f=center 375w, " +
      image.file.url +
      "?w=500&h=400&q=75&fit=fill&f=center 500w, " +
      image.file.url +
      "?w=750&h=600&q=75&fit=fill&f=center 750w, " +
      image.file.url +
      "?w=1600&h=1280&q=75&fit=fill&f=center 1600w",
    media: "",
    sizes: "(max-width: 250px) 100vw, 250px",
    aspectRatio: 1.25,
  }
}

export default createImage
