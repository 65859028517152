import { graphql, useStaticQuery } from "gatsby"
import { SearchConfig } from "../models"

type Props = {
  site: {
    siteMetadata: { search: SearchConfig }
  }
}

const useSearchConfig = (): SearchConfig => {
  const data = useStaticQuery<Props>(graphql`
    query {
      site {
        siteMetadata {
          ...SearchConfig
        }
      }
    }
  `)

  return data.site.siteMetadata.search
}

export default useSearchConfig
